const ReactGA = require('react-ga')

ReactGA.initialize('G-XLKNJCGHG5')
ReactGA.set({
  appName: 'An Engineering Career Ladder'
})

exports.onRouteUpdate = (state) => {
  ReactGA.pageview(state.location.pathname)
}
